.reset {
  background-color: whitesmoke;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reset form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.reset label {
  text-align: left;
}

.reset input {
  outline: none;
  border: none;
  border-radius: 4px;
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 10px 20px;
}
.reset input[type="submit"] {
  background-color: #111;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  transition-duration: 300;
}
.reset input[type="submit"]:hover {
  opacity: 0.8;
}
.reset p {
  margin-bottom: 25px;
}
