/* Any CSS Code we want to add that isnt from webflow can be entered here manually*/
.dropdown-body {
  transition-duration: 0.3s;
  transition-property: max-height;
}
.nav-open .dropdown-body {
  position: static;
  background-color: #d5d5d5;
}
.w-nav-overlay {
  overflow: scroll;
}
.utility-page-wrap {
  padding: 0px;
}
.cover {
  background-color: #fff;
  height: 50px;
  margin-top: -50px;
  position: relative;
  width: 100%;
  z-index: 2 !important;
}
.elfsight-app-2ec4c0a0-c862-4f3f-a8ed-32dee9a3e20a {
  z-index: 1;
}

/* Team slider */
.splide .splide__pagination {
  display: none;
}
.splide__arrows {
  pointer-events: none;
}
.splide__arrows .splide__arrow-embed {
  pointer-events: visible;
}
.splide__item-content {
  pointer-events: none;
}
.splide__item-content .splide__item-title-wrap {
  pointer-events: visible;
}
.splide__slide.is-active .splide__item-img-mask {
  transform: scale(1.25);
}
.splide__slide.is-active .splide__item-img {
  filter: saturate(100%);
}
.splide__slide.is-active .splide__item-title-wrap {
  transition-delay: 0.25s;
  opacity: 1;
  transform: skew(0deg, 0deg) translate(0px, 0em);
}
.splide__slide.is-active .splide__member-position {
  transition-delay: 0.35s;
  opacity: 1;
  transform: skew(0deg, 0deg) translate(0px, 0em);
}
.splide__slide.is-active .splide__item-bio {
  transition-delay: 0.45s;
  opacity: 1;
  transform: translate(0px, 0em);
}
.intro-header {
  margin-bottom: 0;
}
.gradient-cover {
  pointer-events: none;
}

.quote-slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  height: 60vh;
}
.quote-card {
  transition-duration: 500;
}
.quote-card.false {
  width: 0%;
  opacity: 0;
}
.quote-card.show {
  width: 100%;
  opacity: 1;
}
.RichText {
  text-align: center;
}

.faq-body {
  transition: all 300ms ease;
}

input[type="file"] {
  display: none;
}
.div-block-22,
.file-target {
  cursor: pointer;
  transition-duration: 300;
}
.div-block-22:hover,
.file-target:hover {
  opacity: 0.7;
}

.dropdown-body {
  background-color: white;
}
.dropdown-body .navigation-item {
  color: black;
}

.person {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.headshot {
  width: 38%;
  margin-right: 2%;
  min-width: 300px;
}
.personContent {
  max-width: 60%;
  min-width: 300px;
}

.imgRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.imgRow img {
  max-height: 100px;
  margin: 10px 20px;
  max-width: 100vw;
  object-fit: contain;
}

.serviceCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.serviceicon_div {
  width: 30%;
  margin: 10px;
  max-width: 300px;
  min-width: 200px;
}
